<template>
  <div id="conteudo">
    <Titulo texto="Ferramentaria" opacityTitle="Serviços" :servico="true" />
    <cardServicos
      v-for="(conteudo, index) in conteudoCards"
      :key="index"
      :titulo="conteudo.titulo"
      :image="conteudo.imagem"
      :alt="conteudo.alt"
    >
      {{ conteudo.texto }}
    </cardServicos>
    <b-container class="estrutura">
      <b-row>
        <b-col>
          <div class="pl-2 pl-md-5">
            <h3 tabindex="0">Estrutura do setor</h3>
            <ul>
              <li><p tabindex="0">Fresa:</p></li>
              <li>
                <p tabindex="0">Torno Mecânico de 1,5 metros de barramento;</p>
              </li>
              <li>
                <p tabindex="0">Retífica Plana com mesa de 150 x 400 mm;</p>
              </li>
              <li><p tabindex="0">Afiadora Universal;</p></li>
              <li><p tabindex="0">Furadeira com Coordenada;</p></li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import cardServicos from "../../components/cardServicos.vue";
import Titulo from "../../components/Titulo.vue";

export default {
  components: { cardServicos, Titulo },
  data() {
    return {
      conteudoCards: [
        {
          titulo: "Fresa",
          texto:
            "Máquina utilizada para dar um novo tipo de contraste, formato ou utilidade aos metais, modelando peças de acordo com sua especificidade.",
          imagem: "ferramentaria/ferramentaria1.webp",
          alt: "Fresa",
        },
        {
          titulo: "Torno mecânico",
          texto:
            "Máquina destinada a usinagem de peças, além de realizar trabalhos de calibração de furos e peças cilíndricas e roscas. ",
          imagem: "ferramentaria/ferramentaria2.webp",
          alt: "Torno mecânico",
        },
        {
          titulo: "Retífica plana",
          texto:
            "Máquina utilizada na usinagem, permite a produção de diversas peças e ferramentas, de acordo com a necessidade. Esse processo dá um acabamento fino à peças de diversas geometrias. ",
          imagem: "ferramentaria/ferramentaria3.webp",
          alt: "Retífica plana",
        },
        {
          titulo: "Afiadora",
          texto:
            "Máquina voltada a fabricação, afiação e retificação de ferramentas especiais e de precisão.",
          imagem: "ferramentaria/ferramentaria4.webp",
          alt: "Afiadora",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.estrutura {
  color: #ededed;
  h3 {
    font-family: Monument Extended, sans-serif;
    font-size: 2.25rem;
  }

  ul {
    font-size: 1.25rem;
    color: #05c8ef;
    padding-inline-start: 20px;

    li {
      p {
        color: #ededed;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .estrutura {
    h3 {
      font-size: 1.6rem;
    }

    ul {
      font-size: 1rem;
    }
  }
}
</style>
<template>
  <b-container class="my-5 tudo">
    <b-row no-gutters>
      <b-col cols="12" md="6" class="texto-col pb-5 pb-md-0">
        <div class="texto">
          <div>
            <p tabindex="0" class="titulo pb-4">{{ titulo }}</p>
            <p tabindex="0" class="descricao">
              <slot />
            </p>
          </div>
          <botaoPadrao
            tabindex="0"
            botaoBackground="linear-gradient(
    142.85deg,
    rgba(255, 255, 255, 0.16) -0.32%,
    rgba(255, 255, 255, 0) 109.39%,
    rgba(255, 255, 255, 0.04) 109.39%
  )"
            class="botao"
            href="/#contact-session"
          >
            Entre em contato
          </botaoPadrao>
        </div>
      </b-col>
      <b-col cols="12" md="6">
        <b-img
          tabindex="0"
          :alt="alt"
          class="image"
          :src="require('@/assets/Servicos/' + image)"
        >
        </b-img>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
/*
    PROPS:
        -> titulo: titulo
        -> image: imagem recebida pelo require('imagem')
        -> alt: alt da imagem
    
    Para colocar a descrição basta colocar o texto entre as tags
    Ex: 
    <cardServicos
      titulo="Corte de chapa"
      :image="require('../assets/Servicos/corte-chapa1.png')"
      alt="corte de chapa"
    >
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacus,
      magna enim justo. Donec sapien quis libero consectetur. Egestas velit vel
      pharetra, nulla cras.
    </cardServicos>

*/

import botaoPadrao from "@/components/botaoPadrao.vue";

export default {
  name: "cardServicos",
  components: {
    botaoPadrao,
  },
  props: {
    titulo: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: "Imagem de serviço",
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}

.tudo {
  background-color: transparent;
  margin-bottom: 6rem !important;
}

.texto-col {
  background: linear-gradient(90deg, #030a12 0%, #021322 100%);
}

@media only screen and (max-width: 768px) {
  .texto-col {
    background: linear-gradient(180deg, #030a12 0%, #021322 100%);
  }
}

.texto {
  padding: 13% 23% 5% 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .titulo {
    font-family: Monument Extended;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 29px;
    color: #05c8ef;
  }

  .descricao {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 1.3rem;
    line-height: 1.4rem;
    color: #ededed;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 10px;

    div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .titulo {
      font-size: 1.5rem;
      line-height: 1.6rem;
    }

    .descricao {
      font-size: 1rem;
      line-height: 1.1rem;
    }
  }
}

.image {
  width: 100%;
  height: 400px;
  color: white;
  object-fit: cover;
}

.botao {
  // position: relative;
  // top: -50px;
  margin-top: 1rem;
  box-shadow: 0px 4px 24px -1px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}
</style>
